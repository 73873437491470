@use '../_sass/_vars';
@use '../_sass/_mixins';

/*
	Alpha by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

	body, input, select, textarea {
		font-size: 13pt;
	}
	
/* Banner */

	#banner {
		padding: 10em 0 18em 0;
	}
